import { useRecordContext } from 'react-admin';
import Button from '@mui/material/Button';
import Description from '@mui/icons-material/Description';

const CreateInvoiceButton = () => {
    const record = useRecordContext();
    const orderId = record.id;
    const paymentKey = record.order_key;
    const invoiceUrl = `https://clevero.nl/wcpdf/invoice/${orderId}/${paymentKey}/pdf`;

    return (
        <Button
            sx={{ mt: .8 }}
            variant="contained" 
            endIcon={<Description />}
            component="a"
            href={invoiceUrl}
            target="_blank"
            rel="noopener noreferrer"
        >
            Factuur
        </Button>
    );
};

export default CreateInvoiceButton;
