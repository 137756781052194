import InboxIcon from '@mui/icons-material/Inbox';
import {ListGuesser, EditGuesser, ShowGuesser} from "react-admin";
import OrderList from './OrderList';
import OrderEdit from './OrderEdit';
import OrderCreate from './OrderCreate';

export default {
    list: OrderList,
    edit: OrderEdit,
    show: ShowGuesser,
    create: OrderCreate,
    icon: InboxIcon,
};