import React, { useState } from 'react';
import { Create, SimpleForm, TextInput, NumberInput, required, SelectInput, useNotify, useRedirect, useDataProvider } from 'react-admin';
import { Card, CardContent, Typography, Divider, Grid, FormControlLabel, Checkbox, Button, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ProductSelectionModal from './ProductSelectionModal';

const OrderCreate = (props) => {
    const [showShipping, setShowShipping] = useState(false);
    const [lineItems, setLineItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentMethodTitle, setPaymentMethodTitle] = useState('');
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleToggleShipping = () => {
        setShowShipping(!showShipping);
    };

    const handleAddProduct = (product) => {
        if (product && product.name && product.price !== undefined) {
            const newLineItem = { product_id: product.id, name: product.name, price: parseFloat(product.price), quantity: 1 };
            setLineItems([...lineItems, newLineItem]);
        } else {
            console.error('Invalid product data:', product);
        }
        setModalOpen(false);
    };

    const handleRemoveProduct = (index) => {
        const newLineItems = [...lineItems];
        newLineItems.splice(index, 1);
        setLineItems(newLineItems);
    };

    const handleLineItemChange = (index, field, value) => {
        const newLineItems = [...lineItems];
        newLineItems[index] = { ...newLineItems[index], [field]: value };
        setLineItems(newLineItems);
    };

    const calculateTotal = () => {
        return lineItems.reduce((acc, item) => {
            const itemTotal = item.price * item.quantity;
            return acc + itemTotal;
        }, 0).toFixed(2);
    };

    const calculateTax = (total) => {
        const taxRate = 0.21; // Example tax rate of 21%
        return (total * taxRate).toFixed(2);
    };

    const orderTotal = calculateTotal();
    const tax = calculateTax(orderTotal);
    const totalWithoutTax = (parseFloat(orderTotal) - parseFloat(tax)).toFixed(2);

    const handleSubmit = async (data) => {
        const orderData = {
            ...data,
            payment_method: paymentMethod,
            payment_method_title: paymentMethodTitle,
            line_items: lineItems.map(item => ({
                product_id: item.product_id,
                quantity: item.quantity,
            })),
        };

        try {
            await dataProvider.create('orders', { data: orderData });
            notify('Order created successfully', { type: 'success' });
            redirect('list', 'orders');
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handlePaymentMethodChange = (event) => {
        const selectedOption = event.target.value;
        const selectedPaymentMethod = paymentMethods.find(method => method.id === selectedOption);
        setPaymentMethod(selectedOption);
        setPaymentMethodTitle(selectedPaymentMethod ? selectedPaymentMethod.name : '');
    };

    const paymentMethods = [
        { id: 'bacs', name: 'Directe bankoverschrijving' },
        { id: 'cash_on_delivery', name: 'Contant betalen / pinnen bij ontvangst of afhalen' },
    ];

    return (
        <Create {...props} disableAuthentication>
            <SimpleForm onSubmit={handleSubmit}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Factuuradres</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput source="status" label="Status" defaultValue="processing" fullWidth disabled />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.company" label="Bedrijfsnaam" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.first_name" label="Voornaam" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.last_name" label="Achternaam" fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <TextInput source="billing.address_1" label="Adres" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.city" label="Stad" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.postcode" label="Postcode" fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.country" label="Land" defaultValue="NL" disabled fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="billing.email" label="E-mail" fullWidth />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Divider style={{ margin: '20px 0' }} />
                <Card>
                    <CardContent>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={showShipping}
                                    onChange={handleToggleShipping}
                                    name="showShipping"
                                    color="primary"
                                />
                            }
                            label="Verzendadres toevoegen"
                        />
                        {showShipping && (
                            <>
                                <Typography variant="h6" gutterBottom>Verzendadres</Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <TextInput source="shipping.first_name" label="Voornaam" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextInput source="shipping.last_name" label="Achternaam" fullWidth />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextInput source="shipping.address_1" label="Adres" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextInput source="shipping.city" label="Stad" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextInput source="shipping.postcode" label="Postcode" fullWidth />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextInput source="shipping.country" label="Land" fullWidth defaultValue="NL" disabled/>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </CardContent>
                </Card>
                <Divider style={{ margin: '20px 0' }} />
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Artikelen</Typography>
                        <Button variant="contained" color="primary" onClick={() => setModalOpen(true)}>Product toevoegen</Button>
                        <br /><br /><br />
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="h6" gutterBottom>Product</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h6" gutterBottom>Aantal</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="h6" gutterBottom>Prijs per stuk</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography variant="h6" gutterBottom>Totaal</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography variant="h6" gutterBottom></Typography>
                            </Grid>
                        </Grid>
                        {lineItems.map((item, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={6}>
                                    <TextInput 
                                        label={item.name || ''} 
                                        source={`lineItems[${index}].name`} 
                                        value={item.name || ''} 
                                        disabled 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <NumberInput 
                                        label="Aantal"
                                        source={`lineItems[${index}].quantity`} 
                                        value={item.quantity} 
                                        defaultValue="1"
                                        onChange={(e) => handleLineItemChange(index, 'quantity', parseFloat(e.target.value) || 1)} 
                                        validate={required()} 
                                        min="1"
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <NumberInput 
                                        label={item.price} 
                                        source={`lineItems[${index}].price`} 
                                        value={item.price} 
                                        disabled 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <NumberInput 
                                        label={(item.price * item.quantity).toFixed(2)} 
                                        source={`lineItems[${index}].total`} 
                                        value={(item.price * item.quantity).toFixed(2)} 
                                        disabled 
                                        fullWidth 
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton color="secondary" onClick={() => handleRemoveProduct(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
                <Divider style={{ margin: '20px 0' }} />
                <Card>
                    <CardContent>
                        <Typography variant="h6">Totaal exclusief btw: €{totalWithoutTax}</Typography>
                        <Typography variant="h6">Btw: €{tax}</Typography>
                        <Typography variant="h6">Totaal inclusief btw: €{orderTotal}</Typography>
                    </CardContent>
                </Card>
                <Divider style={{ margin: '20px 0' }} />
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Betaalmethode</Typography>
                        <SelectInput 
                            source="payment_method" 
                            label="Betaalmethode" 
                            choices={paymentMethods}
                            validate={required()} 
                            fullWidth
                            onChange={handlePaymentMethodChange}
                        />
                    </CardContent>
                </Card>
            </SimpleForm>
            <ProductSelectionModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSelect={handleAddProduct}
            />
        </Create>
    );
};

export default OrderCreate;
