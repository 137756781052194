import {useRecordContext} from 'react-admin';
import Typography from '@mui/material/Typography';

export default function CustomerField() {
const customer = useRecordContext();
    return (
        <Typography variant="body2">
            Firma: {customer.shipping.company}
            <br />
            Naam: {customer.shipping.first_name} {customer.shipping.last_name}
            <br />
            Adres: {customer.shipping.address_1}, {customer.shipping.postcode}, {customer.shipping.city}
            <br/>
            E-mail: {customer.shipping.email}
            <br/>
            Phone: {customer.shipping.phone}
        </Typography>
    )
};