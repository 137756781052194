import React, { useState } from 'react';
import { Create, SimpleForm, TextInput, NumberInput, SelectInput, useNotify, useRedirect, useDataProvider, required } from 'react-admin';
import { Card, CardContent, Typography, Grid, FormControlLabel, Checkbox, Button, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { uploadImages } from './wpUpload';
import CategorySelectionModal from './CategorySelectionModal';

const ProductCreate = (props) => {
    const [manageStock, setManageStock] = useState(false);
    const [uploadedImages, setUploadedImages] = useState([]);
    const [categoryModalOpen, setCategoryModalOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    const handleToggleManageStock = () => {
        setManageStock(!manageStock);
    };

    const handleAddCategory = (category) => {
        setSelectedCategories([...selectedCategories, category]);
        setCategoryModalOpen(false);
    };

    const handleRemoveCategory = (index) => {
        const newCategories = [...selectedCategories];
        newCategories.splice(index, 1);
        setSelectedCategories(newCategories);
    };


    const handleImageUpload = async (event) => {
        const files = Array.from(event.target.files);

        try {
            const uploadedFiles = await uploadImages(files);
            setUploadedImages([...uploadedImages, ...uploadedFiles]);
            notify('Images uploaded successfully', { type: 'success' });
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handleSubmit = async (data) => {
        const productData = {
            ...data,
            manage_stock: manageStock,
            stock_quantity: manageStock ? data.stock_quantity : null,
            images: uploadedImages.map(image => ({ id: image.id, src: image.src })),
        };

        try {
            await dataProvider.create('products', { data: productData });
            notify('Product created successfully', { type: 'success' });
            redirect('list', 'products');
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const statusChoices = [
        { id: 'private', name: 'private' },
        { id: 'publish', name: 'publish' },
    ];

    return (
        <Create {...props}>
            <SimpleForm onSubmit={handleSubmit}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>Product Information</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextInput source="name" label="Naam" validate={required()} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput source="type" label="Soort product" choices={[{ id: 'simple', name: 'Simple' }]} defaultValue="simple" disabled fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="regular_price" label="Prijs" validate={required()} fullWidth />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectInput source="status" label="Zichtbaarheid" choices={statusChoices} validate={required()} fullWidth />
                            </Grid>
                            <Card>
                                <CardContent>
                                    <Typography variant="h6">Categorieen</Typography>
                                    <Button variant="contained" color="primary" onClick={() => setCategoryModalOpen(true)}>Toevoegen</Button>
                                    {selectedCategories.map((category, index) => (
                                        <Grid container spacing={2} key={index}>
                                            <Grid item xs={10}>{category.name}</Grid>
                                            <Grid item xs={2}>
                                                <IconButton onClick={() => handleRemoveCategory(index)}><DeleteIcon /></IconButton>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </CardContent>
                            </Card>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={manageStock}
                                            onChange={handleToggleManageStock}
                                            name="manageStock"
                                            color="primary"
                                        />
                                    }
                                    label="Voorraad bijhouden?"
                                />
                            </Grid>
                            {manageStock && (
                                <Grid item xs={6}>
                                    <NumberInput source="stock_quantity" label="Voorraad heoveelheid" validate={required()} fullWidth />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextInput source="description" label="Beschrijving" multiline fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <input
                                    accept="image/*"
                                    id="image-upload"
                                    multiple
                                    type="file"
                                    onChange={handleImageUpload}
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="image-upload">
                                    <Button variant="contained" color="primary" component="span">
                                        Upload Images
                                    </Button>
                                </label>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1">Uploaded Images:</Typography>
                                {uploadedImages.map((image, index) => (
                                    <img key={index} src={image.src} alt={image.name} style={{ maxWidth: '100px', margin: '10px' }} />
                                ))}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <CategorySelectionModal
                    open={categoryModalOpen}
                    onClose={() => setCategoryModalOpen(false)}
                    onSelect={handleAddCategory}
                />
            </SimpleForm>
        </Create>
    );
};

export default ProductCreate;
