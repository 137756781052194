import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    MenuItem,
    Select,
    FormControl,
    InputLabel
} from '@mui/material';

const ProductSelectionModal = ({ open, onClose, onSelect }) => {
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1);
    const [statusFilter, setStatusFilter] = useState('');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const dataProvider = useDataProvider();

    useEffect(() => {
        if (open) {
            setSearch('');
            setPage(1);
            setStatusFilter('');
            fetchAllProducts();
        }
    }, [open]);

    const fetchAllProducts = async () => {
        setLoading(true);
        setError(null);
        try {
            let allProducts = [];
            let currentPage = 1;
            let totalFetched = 0;
            let totalProducts = 0;

            do {
                const { data, total } = await dataProvider.getList('products', {
                    pagination: { page: currentPage, perPage: 100 },
                    sort: { field: 'name', order: 'ASC' },
                    filter: {}
                });
                allProducts = [...allProducts, ...data];
                totalFetched += data.length;
                totalProducts = total;
                currentPage++;
            } while (totalFetched < totalProducts);

            setProducts(allProducts);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = (event) => {
        setStatusFilter(event.target.value);
        setPage(1); // Reset to the first page when filter changes
    };

    const translateStatus = (status) => {
        return status === 'publish' ? 'Openbaar' : 'Privé';
    };

    const filterProducts = () => {
        let filteredProducts = products;
        if (statusFilter) {
            filteredProducts = filteredProducts.filter(product => product.status === statusFilter);
        }
        if (search) {
            filteredProducts = filteredProducts.filter(product =>
                product.name.toLowerCase().includes(search.toLowerCase())
            );
        }
        return filteredProducts.slice((page - 1) * 10, page * 10);
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Select Product</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Search"
                    type="text"
                    fullWidth
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={statusFilter}
                        onChange={handleStatusChange}
                    >
                        <MenuItem value=''><em>All</em></MenuItem>
                        <MenuItem value='publish'>Openbaar</MenuItem>
                        <MenuItem value='private'>Privé</MenuItem>
                    </Select>
                </FormControl>
                {loading && <CircularProgress />}
                {error && <p>Error loading products: {error.message}</p>}
                <List>
                    {filterProducts().map(product => (
                        <ListItem button key={product.id} onClick={() => onSelect(product)}>
                            <ListItemText
                                primary={`${product.name} - €${product.price}`}
                                secondary={`${translateStatus(product.status)} - Voorraad: ${product.stock_quantity}`}
                            />
                        </ListItem>
                    ))}
                </List>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                    <Button disabled={page === 1} onClick={() => setPage(page - 1)}>Previous</Button>
                    <Button disabled={(page * 10) >= products.length} onClick={() => setPage(page + 1)}>Next</Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductSelectionModal;
