import axios from 'axios';

const WP_API_URL = 'https://clevero.nl/wp-json/wp/v2/media';

export async function uploadImage(file) {
    const token = localStorage.getItem('auth_token');
    if (!token) {
        throw new Error('No authentication token found');
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', file.name);

    try {
        const response = await axios.post(WP_API_URL, formData, {
            headers: {
                'Content-Disposition': `attachment; filename="${file.name}"`,
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error) {
        throw new Error(`Failed to upload image: ${error.message}`);
    }
}

export async function uploadImages(files) {
    const uploadedImages = [];
    for (const file of files) {
        try {
            const imageData = await uploadImage(file);
            uploadedImages.push({ id: imageData.id, src: imageData.source_url });
        } catch (error) {
            console.error(`Error uploading file ${file.name}:`, error);
        }
    }
    return uploadedImages;
}
