// CategorySelectionModal.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { useDataProvider } from 'react-admin';

const CategorySelectionModal = ({ open, onClose, onSelect }) => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (open) {
            setLoading(true);
            dataProvider.getList('products/categories', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'name', order: 'ASC' },
                filter: {},
            })
                .then(({ data }) => {
                    setCategories(data);
                    setLoading(false);
                })
                .catch(error => {
                    setError(error);
                    setLoading(false);
                });
        }
    }, [open, dataProvider]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Categorieen selecteren</DialogTitle>
            <DialogContent>
                {loading && <CircularProgress />}
                {error && <p>Error loading categories: {error.message}</p>}
                <List>
                    {categories.map(category => (
                        <ListItem button key={category.id} onClick={() => onSelect(category)}>
                            <ListItemText primary={category.name} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default CategorySelectionModal;
