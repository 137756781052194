import React from 'react';
import { Show, SimpleShowLayout, TextField, useRecordContext } from 'react-admin';

const ContainersShow = (props) => {
    const record = useRecordContext();
    const documentUrls = record.document_urls

    const renderDocuments = (documentUrls) => {
        if (!documentUrls || documentUrls.trim() === '') return <p>No documents available.</p>;

        const urls = documentUrls.split(',').filter(Boolean); // Ensure no empty strings are considered
        if (urls.length === 0) return <p>No documents available.</p>;

        return (
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {urls.map((url, index) => (
                    <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        {url.match(/\.(jpeg|jpg|gif|png)$/) ? (
                            <img src={url} alt={`Document ${index + 1}`} style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                        ) : (
                            <span style={{ marginRight: '10px' }}>
                                📄
                            </span>
                        )}
                        <a href={url} target="_blank" rel="noopener noreferrer">
                            {url}
                        </a>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="supplier" />
                <TextField source="comment" />
                <TextField source="document_urls" label="Document URLs" />
                <div>
                    <strong>Uploaded Documents</strong>
                    {renderDocuments()}
                </div>
            </SimpleShowLayout>
        </Show>
    );
};

export default ContainersShow;
