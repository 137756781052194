import React, { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput, Labeled, TextField, useNotify, useRedirect, useDataProvider, Button } from 'react-admin';
import { uploadImages } from '../products/wpUpload';

const ContainersEdit = (props) => {
    const [uploadedDocuments, setUploadedDocuments] = useState([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    useEffect(() => {
        // Initialize uploadedDocuments with the existing URLs from the record
        if (props.record && props.record.document_urls) {
            setUploadedDocuments(props.record.document_urls.split(',').map(url => ({ src: url })));
        }
    }, [props.record]);

    const handleDocumentUpload = async (event) => {
        const files = Array.from(event.target.files);
        console.log('Selected files:', files);

        try {
            const uploadedFiles = await uploadImages(files);
            console.log('Uploaded files:', uploadedFiles);

            // Update state with new document URLs
            setUploadedDocuments([...uploadedDocuments, ...uploadedFiles]);

            notify('Documents uploaded successfully', { type: 'success' });
        } catch (error) {
            console.error('Upload error:', error);
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handleRemoveDocument = (index) => {
        const updatedDocuments = uploadedDocuments.filter((_, i) => i !== index);
        setUploadedDocuments(updatedDocuments);
    };

    const handleSubmit = async (values) => {
        // Prepare the document URLs for submission
        const documentUrls = uploadedDocuments.map(doc => doc.src).join(',');

        const containerData = {
            ...values,
            document_urls: documentUrls,
        };

        console.log('Data to be submitted:', containerData);

        try {
            await dataProvider.update('containers', { id: props.id, data: containerData });
            notify('Container updated successfully');
            redirect('list', 'containers');
        } catch (error) {
            console.error('Submission error:', error);
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    return (
        <Edit {...props}>
            <SimpleForm onSubmit={handleSubmit}>
                <TextInput source="supplier" />
                <TextInput source="comment" multiline />
                <input
                    type="file"
                    accept="application/pdf, image/*"
                    multiple
                    onChange={handleDocumentUpload}
                />
                <div>
                    <Labeled label="Uploaded Documents">
                        {uploadedDocuments.length > 0 ? (
                            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                                {uploadedDocuments.map((doc, index) => (
                                    <li key={index} style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                                        {doc.src.match(/\.(jpeg|jpg|gif|png)$/) ? (
                                            <img src={doc.src} alt={`Document ${index + 1}`} style={{ width: '50px', height: '50px', marginRight: '10px' }} />
                                        ) : (
                                            <span style={{ width: '50px', height: '50px', marginRight: '10px', backgroundColor: '#f0f0f0', textAlign: 'center', lineHeight: '50px' }}>
                                                📄
                                            </span>
                                        )}
                                        <a href={doc.src} target="_blank" rel="noopener noreferrer">
                                            {doc.src}
                                        </a>
                                        <Button
                                            label="Remove"
                                            onClick={() => handleRemoveDocument(index)}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <p>No documents uploaded yet.</p>
                        )}
                    </Labeled>
                </div>
                <Labeled label="Document URLs">
                    <TextField source="document_urls" record={{ document_urls: uploadedDocuments.map(doc => doc.src).join(',') }} />
                </Labeled>
                <TextInput source="document_urls" multiline disabled />
            </SimpleForm>
        </Edit>
    );
};

export default ContainersEdit;
