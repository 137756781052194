import { Fragment, useCallback } from 'react';
import { 
    Datagrid,
    List, 
    TextInput, 
    TextField, 
    useGetList, 
    useListContext, 
    FunctionField
} from 'react-admin';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import parse from 'html-react-parser';


const ProductList = () => (
    <List 
        filters={productFilters}
        filterDefaultValues={{ status: 'processing' }}
        sort={{ order: 'desc' }}
    >
        <TabbedDatagrid />
    </List>
);

const productFilters = [<TextInput source="search" alwaysOn />];

const tabs = [
    { id: 'publish', name: 'Openbaar' },
    { id: 'private', name: 'Prive' },
];

const useGetTotals = (filterValues) => {
    const { total: totalpublish } = useGetList('products', {
        filter: { ...filterValues, status: 'publish' },
    });
    const { total: totalprivate } = useGetList('products', {
        filter: { ...filterValues, status: 'private' },
    });
    return {
        publish: totalpublish,
        private: totalprivate,
    };
};

const CustomEmpty = () => (<Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">No products found</Typography>)

const TabbedDatagrid = () => {
    const dateSettings = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    const totals = useGetTotals(filterValues);

    const handleChange = useCallback(
        (event, value) => {
        setFilters &&
            setFilters (
                { ...filterValues, status: value }, 
                displayedFilters, 
                false
            );
        }, 
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant='fullWidth'
                centered
                value={filterValues.status || 'publish'}
                indicatorColor='primary'
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            totals[choice.name]
                                ? `${choice.name} (${totals[choice.name]})`
                                : choice.name}
                            value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider />
            {filterValues.status === 'publish' && (
                <Datagrid optimized rowClick='edit' empty={<CustomEmpty />}>
                    <TextField 
                        source="id" 
                        label="ID"
                    />
                    <FunctionField 
                        label="Naam"
                        render={record => `${record.name}`} />
                    <FunctionField 
                        label="Beschrijving"
                        render={record => parse(record.description)} />
                    <FunctionField
                        label="Voorraad bijhouden"
                        render={record => record.manage_stock ? '✔️' : '❌'}
                    />
                    <FunctionField 
                        label="Voorraad"
                        render={record => `${record.stock_quantity}`} />
                    <FunctionField 
                        label="Prijs"
                        render={record => `${record.price}`}
                    />
                </Datagrid>
            )}
            {filterValues.status === 'private' && (
                <Datagrid rowClick='edit' >
                                       <TextField 
                        source="id" 
                        label="ID"
                    />
                    <FunctionField 
                        label="Naam"
                        render={record => `${record.name}`} />
                    <FunctionField 
                        label="Beschrijving"
                        render={record => parse(record.description)} />
                    <FunctionField
                        label="Voorraad bijhouden"
                        render={record => record.manage_stock ? '✔️' : '❌'}
                    />
                    <FunctionField 
                        label="Voorraad"
                        render={record => `${record.stock_quantity}`} />
                    <FunctionField 
                        label="Prijs"
                        render={record => `${record.price}`}
                    />
                </Datagrid>
            )}
        </Fragment>
    )
};

export default ProductList;