import React, { useState, useEffect } from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, SelectInput, useNotify, useRedirect, useDataProvider, required, useRecordContext } from 'react-admin';
import { Card, CardContent, Typography, Grid, FormControlLabel, Checkbox, Button, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import {RichTextInput} from 'ra-input-rich-text';
import { uploadImages } from './wpUpload';
import CategorySelectionModal from './CategorySelectionModal';

const ProductEditForm = () => {
    const record = useRecordContext();
    const [manageStock, setManageStock] = useState(record ? record.manage_stock : false);
    const [uploadedImages, setUploadedImages] = useState(record ? record.images : []);
    const [categoryModalOpen, setCategoryModalOpen] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const notify = useNotify();
    const redirect = useRedirect();
    const dataProvider = useDataProvider();

    useEffect(() => {
        if (record) {
            setManageStock(record.manage_stock || false);
            setUploadedImages(record.images || []);
            setSelectedCategories(record.categories || []);
        }
    }, [record]);

    const handleToggleManageStock = () => {
        setManageStock(!manageStock);
    };

    const handleImageUpload = async (event) => {
        const files = Array.from(event.target.files);

        try {
            const uploadedFiles = await uploadImages(files);
            setUploadedImages([...uploadedImages, ...uploadedFiles]);
            notify('Images uploaded successfully', { type: 'success' });
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const handleRemoveImage = (index) => {
        const newImages = [...uploadedImages];
        newImages.splice(index, 1);
        setUploadedImages(newImages);
    };

    const handleAddCategory = (category) => {
        setSelectedCategories([...selectedCategories, category]);
        setCategoryModalOpen(false);
    };

    const handleRemoveCategory = (index) => {
        const newCategories = [...selectedCategories];
        newCategories.splice(index, 1);
        setSelectedCategories(newCategories);
    };

    const handleSubmit = async (data) => {
        const productData = {
            ...data,
            manage_stock: manageStock,
            stock_quantity: manageStock ? data.stock_quantity : null,
            images: uploadedImages.map(image => ({ id: image.id, src: image.src })),
        };

        try {
            await dataProvider.update('products', { id: record.id, data: productData });
            notify('Product updated successfully', { type: 'success' });
            redirect('list', 'products');
        } catch (error) {
            notify(`Error: ${error.message}`, { type: 'error' });
        }
    };

    const statusChoices = [
        { id: 'private', name: 'private' },
        { id: 'publish', name: 'publish' },
    ];

    if (!record) {
        return <div>Loading...</div>;
    }

    return (
        <SimpleForm onSubmit={handleSubmit}>
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Product Information</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextInput source="name" label="Naam" validate={required()} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput source="status" label="Zichtbaarheid" defaultValue="status" choices={statusChoices} validate={required()} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput source="regular_price" label="Prijs per stuk" validate={required()} fullWidth />
                        </Grid>
                        <Card>
                    <CardContent>
                        <Typography variant="h6">Categorieen</Typography>
                        <Button variant="contained" color="primary" onClick={() => setCategoryModalOpen(true)}>Toevoegen</Button>
                        {selectedCategories.map((category, index) => (
                            <Grid container spacing={2} key={index}>
                                <Grid item xs={10}>{category.name}</Grid>
                                <Grid item xs={2}>
                                    <IconButton onClick={() => handleRemoveCategory(index)}><DeleteIcon /></IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </CardContent>
                </Card>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={manageStock}
                                        onChange={handleToggleManageStock}
                                        name="manageStock"
                                        color="primary"
                                    />
                                }
                                label="Voorraad bijhouden?"
                            />
                        </Grid>
                        {manageStock && (
                            <Grid item xs={6}>
                                <NumberInput source="stock_quantity" label="Voorraad" validate={required()} fullWidth min={1} />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <RichTextInput source="description" label="Omschrijving" validate={required()} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>Afbeeldingen</Typography>
                            <input
                                accept="image/*"
                                id="image-upload"
                                multiple
                                type="file"
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                            <label htmlFor="image-upload">
                                <Button variant="contained" color="primary" component="span">
                                    Upload afbeelding
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                {uploadedImages.map((image, index) => (
                                    <Grid item xs={3} key={image.id} style={{ position: 'relative' }}>
                                        <img src={image.src} alt={image.name} style={{ maxWidth: '100%', margin: '10px 0' }} />
                                        <IconButton
                                            onClick={() => handleRemoveImage(index)}
                                            style={{ position: 'absolute', top: 0, right: 0 }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <CategorySelectionModal
                    open={categoryModalOpen}
                    onClose={() => setCategoryModalOpen(false)}
                    onSelect={handleAddCategory}
                />
        </SimpleForm>
    );
};

const ProductEdit = (props) => (
    <Edit {...props}>
        <ProductEditForm />
    </Edit>
);

export default ProductEdit;
