import { useRecordContext } from 'react-admin';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

export default function OrderTotal() {
  const record = useRecordContext();
  const productQuantity = record?.line_items?.reduce((previousValue, currentValue) => previousValue + currentValue.quantity, 0) ?? 0;
  const shipping = record?.shipping_lines?.[0]?.method_title ?? 'N/A';
  const orderTotal = (record?.total);
  const productTotal = record?.line_items?.reduce((previousValue, currentValue) => previousValue + Number(currentValue.subtotal), 0) ?? 0;
  const shippingTotal = record?.shipping_lines?.[0]?.total ?? 0;
  const taxTotal = Number(record?.total_tax) || 0;
  const taxText = record?.tax_lines?.[0]?.label;


  const CouponTitle = () => {
    if (!record?.coupon_lines?.length) return null;
    return <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>Coupons</Typography>;
  }

  const Coupon = () => {
    if (!record?.coupon_lines?.length) return null;
    return <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>{record.coupon_lines[0].code}</Typography>;
  }

  const CouponTotal = () => {
    if (!record?.coupon_lines?.length) return null;
    return <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }} align='right'>-{record.discount_total} {record.currency_symbol}</Typography>;
  }

  const FeeTitle = () => {
    if (!record?.fee_lines?.length) return null;
    return <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>Fees</Typography>;
  }

  const Fee = () => {
    if (!record?.fee_lines?.length) return null;
    return <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>{record.fee_lines[0].name}</Typography>;
  }

  const FeeTotal = () => {
    if (!record?.fee_lines?.length) return null;
    return <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }} align='right'>{record.fee_lines[0].total} {record.currency_symbol}</Typography>;
  }

  const RefundTitle = () => {
    if (!record?.refunds?.length) return null;
    return <Typography variant="body2" sx={{ color: 'error.main', mt: 1.5, mb: 1.5 }}>Refunded</Typography>;
  }

  const RefundTotal = () => {
    if (!record?.refunds?.length) return null;
    const totalRefund = record.refunds.reduce((previousValue, currentValue) => previousValue + Number(currentValue.total), 0);
    return <Typography variant="body2" sx={{ color: 'error.main', mt: 1.5, mb: 1.5 }} align='right'>{totalRefund} {record.currency_symbol}</Typography>;
  }

  const NetTotal = () => {
    if (!record?.refunds?.length) {
      return <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1.5 }} align='right'>{orderTotal} {record.currency_symbol}</Typography>;
    }
    const totalRefund = record.refunds.reduce((previousValue, currentValue) => previousValue + Number(currentValue.total), 0);
    const netPayment = totalRefund + orderTotal;
    return <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1.5 }} align='right'>{netPayment.toFixed(2)} {record.currency_symbol}</Typography>;
  }

  return (
    <Grid container>
      <Grid item xs={4}>
        <Typography variant="body2" sx={{ mb: 1.5 }}>Aantal producten</Typography>
        <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>BTW</Typography>
        <FeeTitle />
        <CouponTitle />
        <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1.5, mb: 1.5 }}>Totaalbedrag</Typography>
        <Divider />
        <RefundTitle />
        <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1.5 }}>Netto betaling</Typography>
      </Grid>
      <Grid item xs={3}>
        <Typography variant="body2" sx={{ mb: 1.5 }}>{productQuantity} product(en)</Typography>
        <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>{taxText}</Typography>
        <Fee />
        <Coupon />
        <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }}>&nbsp;</Typography>
        <Divider />
      </Grid>
      <Grid item xs={5}>
        <Typography variant="body2" sx={{ mb: 1.5 }} align='right'>{productTotal.toLocaleString()} {record.currency_symbol}</Typography>
        <Typography variant="body2" sx={{ mt: 1.5, mb: 1.5 }} align='right'>{taxTotal.toLocaleString()} {record.currency_symbol}</Typography>
        <FeeTotal />
        <CouponTotal />
        <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 1.5, mb: 1.5 }} align='right'>{orderTotal} {record.currency_symbol}</Typography>
        <Divider />
        <RefundTotal />
        <NetTotal />
      </Grid>
    </Grid>
  );
}
