// src/authProvider.js

import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import axios from 'axios';

const WP_API_URL = 'https://clevero.nl/wp-json/jwt-auth/v1/token';

export default {
    login: async ({ username, password }) => {
        try {
            const response = await axios.post(WP_API_URL, { username, password });
            localStorage.setItem('auth_token', response.data.token);
            return Promise.resolve();
        } catch (error) {
            return Promise.reject(error);
        }
    },
    logout: () => {
        localStorage.removeItem('auth_token');
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.status === 401 || error.status === 403) {
            localStorage.removeItem('auth_token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('auth_token') ? Promise.resolve() : Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
};
