import { BrowserRouter } from 'react-router-dom';
import { Admin, Resource, defaultDarkTheme, combineDataProviders } from 'react-admin';
import { deepmerge } from '@mui/utils';
import woocommerceData from 'ra-data-woocommerce';
import orders from './orders';
import refunds from './refunds';
import products from './products';
import ContainersList from './containers/ContainersList';
import ContainersShow from './containers/ContainersShow';
import ContainersCreate from './containers/ContainersCreate';
import ContainersEdit from './containers/ContainersEdit';
import { red } from '@mui/material/colors';
import authProvider from './authProvider';
import LoginPage from './LoginPage.js';
const config = require('./config');



const woocommerceDataProvider = woocommerceData({
    woocommerceUrl: config.siteUrl,
    consumerKey: config.consumerKey,
    consumerSecret: config.consumerSecret,
});

const dataProvider = combineDataProviders((resource) => {
    switch (resource) {
        case 'orders':
        case 'products':
        case 'refunds':
            return woocommerceDataProvider;
        default:
            throw new Error(`Unknown resource: ${resource}`);
    }
});

const myTheme = deepmerge(defaultDarkTheme, {
    palette: {
        primary: {
            main: '#B3DD67',
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
});

const App = () => (
    <BrowserRouter>
        <Admin
            dataProvider={dataProvider}
            theme={myTheme}
            authProvider={authProvider}
            loginPage={LoginPage}
        >
            
            <Resource name="orders" {...orders} />
            <Resource name="orders/:id/refunds" {...refunds} />
            <Resource name="products" {...products} />
        </Admin>
    </BrowserRouter>
);



export default App;
